/** @format */

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Layout, Carousel } from "components";
import { BookList } from "containers";

const bookGenres = [
  "자기계발",
  "가정육아",
  "건강",
  "건축",
  "경제경영",
  "과학",
  "기타",
  "국내소설",
  "국외소설",
  "시",
  "에세이",
  "여행",
  "역사문화",
  "예술",
  "요리",
  "음악",
  "인문",
  "정치사회",
  "취미/실용",
];

const Sanchek = () => {
  const data = useStaticQuery(query);
  const imgList = data.allStrapiSanchek.nodes[0].carousel_img;
  const pageDescription = data.strapiSanchek.page_description;
  return (
    <Layout>
      <Carousel
        imgList={imgList}
        pageTitle={"산&책"}
        pageDescription={pageDescription}
      />
      <BookList title="도서 소개" bookGenres={bookGenres} />
    </Layout>
  );
};
const query = graphql`
  query {
    allStrapiSanchek {
      nodes {
        carousel_img {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
    }
    strapiSanchek {
      page_description
    }
  }
`;

export default Sanchek;
